<template>
  <div class="filePond">
    <file-pond
      ref="pond"
      name="filepond"
      :label-idle="title"
      :allow-multiple="allowMultiple"
      accepted-file-types="image/*"
      :server="server"
      @init="handleFilePondInit"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

import { getDomain } from '../services/backend';

export default {
  name: 'ImageUpload',

  components: {
    FilePond,
  },

  props: {
    dir: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    server() {
      const domain = getDomain();
      const path = `${domain}/image`;

      return {
        process: {
          url: `${path}/upload?dir=${this.dir}&name=${this.fileName}`,
          method: 'POST',
          onload: data => this.uploadComplete(data),
          onerror: e => {
            this.uploadComplete('');
            console.error(e);
          },
        },
        // revert: {
        //   url: `${path}/delete?dir=${this.dir}&name=${this.fileName}`,
        //   method: 'GET',
        //   onload: (data) => {
        //     this.$emit('revertCompleted')
        //   },
        //   onerror: (e) => {
        //     console.error(e)
        //   },
        // },
      };
    },
  },

  methods: {
    uploadComplete(data) {
      const { image } = JSON.parse(data);

      this.$emit('upload-completed', { image });
    },

    handleFilePondInit: function() {
      // FilePond instance methods are available on `this.$refs.pond`
    },
  },
};
</script>
