<template>
  <f7-page
    name="prayers"
    class="prayersPage"
    @page:afterin="afterIn"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>My Thoughts & Prayers</f7-nav-title>
    </f7-navbar>

    <f7-block class="margin-top no-margin-bottom">
      <Chip text="Here you can view all Prayers and Thoughts sent to you by your family and frineds." />
    </f7-block>

    <template v-if="prayers.length">
      <f7-card
        v-for="prayer in prayers"
        :key="prayer.id"
        outline
        :title="`From ${prayer.from_user.name}`"
        :content="prayer.content"
      >
        <f7-card-footer>
          <f7-link
            color="red"
            @click="removePrayer(prayer.id)"
          >
            Delete
          </f7-link>
        </f7-card-footer>
      </f7-card>
    </template>

    <div>
      <f7-fab
        slot="fixed"
        position="right-bottom"
        text="Send Thoughts & Prayers"
        @click="sheetOpened = true; sheetKey += 1"
      />
    </div>

    <f7-popup :opened="sheetOpened">
      <f7-page class="prayersSheetPage">
        <f7-navbar title="Send Thoughts & Prayers">
          <f7-nav-right>
            <f7-link @click="sheetOpened = !sheetOpened">
              Close
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <send-prayers
          :key="sheetKey"
          :friends="friends"
          @doPrayer="doPrayer"
        />
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import { createPrayer, deletePrayer, getFriends, getPrayers, getUser } from '../services/backend'
import Chip from '../components/Chip';
import SendPrayers from '../components/prayers/SendPrayers';

export default {
  name: 'PrayersThoughts',

  components: {
    Chip,
    SendPrayers,
  },

  data() {
    return {
      user: null,
      prayers: [],
      friends: [],
      sheetOpened: false,
      sheetKey: 0,
    };
  },

  async mounted() {
    this.$f7.dialog.preloader();
  },

  methods: {
    async afterIn() {
      this.user = await getUser();

      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async getRequestData() {
      const { uuid } = this.user;

      this.prayers = await getPrayers({ uuid });
      this.friends = await getFriends({ uuid });
    },

    async doPrayer({ friendId, content, checked }) {
      const { uuid } = this.user;

      if(checked === true) {
        await createPrayer({ uuid, friendId, content });
      }
    },

    async removePrayer(id) {
      const { uuid } = this.user;

      this.$f7.dialog.preloader();

      await deletePrayer({ uuid, id });

      await this.getRequestData();

      this.$f7.dialog.close();
    },
  },
};
</script>
