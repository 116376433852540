<template>
  <div class="chip chip-custom">
    <f7-icon
      f7="info_circle"
    />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'Chip',

  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
