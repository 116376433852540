import HomePage from '../pages/home.vue';
import MemoriesPage from '../pages/memories.vue';
import ProfilePage from '../pages/profile.vue';
import DetailsPage from '../pages/details.vue';
import WishesPage from '../pages/wishes.vue';
import ConnectionsPage from '../pages/connections.vue';
import WishesAddPage from '../pages/wishes-add.vue';
import DocumentsPage from '../pages/documents.vue';
import ConnectionDetailsPage from '../pages/connection-details.vue';
import AboutPage from '../pages/about.vue';
import PrayersThoughtsPage from '../pages/prayers-thoughts.vue';
import SignUpPage from '../pages/sign-up';

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/memories/',
    component: MemoriesPage,
  },
  {
    path: '/profile/',
    component: ProfilePage,
  },
  {
    path: '/details/',
    component: DetailsPage,
  },
  {
    path: '/wishes/',
    component: WishesPage,
  },
  {
    path: '/connections/',
    component: ConnectionsPage,
  },
  {
    path: '/wishes-add/',
    component: WishesAddPage,
  },
  {
    path: '/documents/',
    component: DocumentsPage,
  },
  {
    path: '/connection-details/',
    component: ConnectionDetailsPage,
  },
  {
    path: '/prayers-thoughts/',
    component: PrayersThoughtsPage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/sign-up/',
    component: SignUpPage,
  },
];

export default routes;
