<template>
  <div>
    <template v-if="phoneContacts.length || friends.length">
      <f7-block class="margin-top no-margin-bottom">
        <Chip
          class="margin-bottom"
          text="Select a contact to whom you would like to create a wish for."
        />
        <f7-button
          large
          fill
          @click="importFromContacts"
        >
          Select from contacts
        </f7-button>
      </f7-block>

      <br>
    </template>

    <template v-else>
      <f7-block>
        <f7-button
          fill
          @click="goToConnections"
        >
          Add Friends & Family
        </f7-button>
      </f7-block>
    </template>

    <f7-block class="margin-top no-margin-bottom">
      <Chip text="Or enter new details." />
    </f7-block>
    <f7-list
      class="margin-top"
      inset
    >
      <f7-list-input
        type="text"
        label="First Name"
        placeholder="First Name"
        :value="form.name"
        :default-value="form.firstName"
        clear-button
        @input="form.name = $event.target.value"
      />
      <f7-list-input
        type="email"
        label="Email Address"
        placeholder="Email Address"
        :value="form.email"
        :default-value="form.email"
        clear-button
        @input="form.email = $event.target.value"
      />
      <f7-list-input
        type="tel"
        label="Phone Number"
        placeholder="Phone Number"
        :value="form.phone"
        :default-value="form.phone"
        clear-button
        @input="form.phone = $event.target.value"
      />
      <f7-list-button
        title="Continue to wish details"
        color="blue"
        @click="updateDetails"
      />
    </f7-list>

    <f7-popup :opened="contactsSheetOpened">
      <f7-page class="homePage">
        <f7-navbar title="Contact list">
          <f7-nav-right>
            <f7-link @click="contactsSheetOpened = !contactsSheetOpened">
              Close
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <Contacts
          v-if="phoneContacts.length || friends.length"
          :phone-contacts="phoneContacts"
          :friends="friends"
          @import-contact="importContact"
          @select-user="selectUser"
        />
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
// import contactData from '../../services/contactData.json';
import Contacts from '../../components/connections/Contacts';
import Chip from '../../components/Chip';

export default {
  name: 'Friends',

  components: {
    Contacts,
    Chip,
  },

  props: {
    friends: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      contactsSheetOpened: false,
      phoneContacts: [],
      form: {
        name: null,
        email: null,
        phone: null,
      },
    };
  },

  methods: {
    selectUser({ forUserId }) {
      this.contactsSheetOpened = false;
      this.$emit('select-user', { forUserId });
    },

    importContact(data) {
      this.contactsSheetOpened = false;
      this.$emit('select-user', data);
    },

    importFromContacts() {
      this.contactsSheetOpened = true;

      // eslint-disable-next-line no-undef
      // if (process.env.NODE_ENV === 'development') {
      //   this.phoneContacts = this.parseContacts(contactData);
      // }

      /*
      if (window.ContactsX) {
        window.ContactsX.requestPermission(() => {
          this.getContacts();
        }, error => {
          console.error('requestPermission error', error);
        });

        window.ContactsX.hasPermission(() => {
          this.getContacts();
        }, error => {
          console.error('hasPermission error', error);
        });
      }
      */
    },

    goToConnections() {
      this.contactsSheetOpened = false;
      this.$emit('go-to-connections');
    },

    getContacts() {
      window.ContactsX.find(contacts => {
        this.contactsSheetOpened = true;
        this.phoneContacts = this.parseContacts(contacts);
      }, error => {
        console.error('find error', error);
      }, {
        fields: {
          phoneNumbers: true,
          emails: true,
        },
      });
    },

    /**
     * @param contacts
     * @returns {*}
     */
    parseContacts(contacts) {
      contacts = contacts.filter(contact => contact.phoneNumbers.length);

      contacts = contacts.map(contact => {
        const { emails, phoneNumbers, firstName, middleName, familyName } = contact;
        contact.name = `${firstName} ${middleName} ${familyName}`.trim();
        contact.phone = phoneNumbers[0] || null;
        contact.email = emails[0] && emails[0].value
            ? emails[0].value
            : null;

        return contact;
      });

      return contacts;
    },

    async updateDetails() {
      if (this.form.name === null || this.form.name.trim() === '') {
        this.$f7.dialog.alert('The Name is required', '');
        return;
      }

      if (this.form.phone === null && this.form.email === null) {
        this.$f7.dialog.alert('An Email or Phone Number is required', '');
        return;
      }

      this.$emit('select-user', this.form);
    },
  },
};
</script>
