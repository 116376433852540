<template>
  <f7-card>
    <f7-card-content v-html="todo.content" />
    <f7-card-footer>
      <f7-link
        v-if="!disabled"
        color="red"
        @click="removeTodo"
      >
        Delete
      </f7-link>
      <div v-if="!disabled">
        <span>Mark completed</span>
        <f7-toggle
          :checked="todo.done === 'yes'"
          @change="toggleTodo"
        />
      </div>

      <div v-if="disabled">
        Completed: {{ todo.done.toUpperCase() }}
      </div>
    </f7-card-footer>
  </f7-card>
</template>

<script>
export default {
  name: 'TodoItem',

  props: {
    todo: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    removeTodo() {
      this.$emit('remove-todo', this.todo);
    },

    toggleTodo(e) {
      this.$emit('toggle-todo', {
        id: this.todo.id,
        done: e.target.checked ? 'yes' : 'no',
      });
    },
  },
};
</script>
