<template>
  <div class="ImagesComponent">
    <f7-row no-gap>
      <f7-col v-if="images[0]">
        <img
          class="cover-image"
          :src="images[0]"
          width="100%"
          alt=""
        >
      </f7-col>
      <f7-col v-if="images[1]">
        <img
          class="cover-image"
          :src="images[1]"
          width="100%"
          alt=""
        >
      </f7-col>
      <f7-col v-if="images[2]">
        <img
          class="cover-image"
          :src="images[2]"
          width="100%"
          alt=""
        >
      </f7-col>
    </f7-row>
    <div
      v-if="images.length > 3"
      class="ImagesCount"
    >
      +{{ images.length - 3 }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Images',

  props: {
    images: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      w: window.innerWidth,
    };
  },
};
</script>
