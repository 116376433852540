<template>
  <f7-page
    name="details"
    class="detailsPage"
    @page:afterin="afterIn"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>Ceremony & Resting place</f7-nav-title>
    </f7-navbar>

    <f7-list
      v-if="form"
      class="margin-top"
      inset
    >
      <f7-list-input
        type="text"
        label="Ceremony Place"
        placeholder="Ceremony Place"
        :value="form.ceremonyPlace"
        :default-value="form.ceremonyPlace"
        clear-button
        @input="saveInput($event.target.value, 'ceremonyPlace')"
      />

      <f7-list-input
        type="text"
        label="Resting Place"
        placeholder="Resting Place"
        :value="form.restingPlace"
        :default-value="form.restingPlace"
        clear-button
        @input="saveInput($event.target.value, 'restingPlace')"
      />

      <f7-list-input
        type="text"
        label="Legal Representative"
        placeholder="Legal Representative"
        :value="form.representative"
        :default-value="form.representative"
        clear-button
        @input="saveInput($event.target.value, 'representative')"
      />

      <f7-list-input
        type="text"
        label="Clergy Representative"
        placeholder="Clergy Representative"
        :value="form.clergy"
        :default-value="form.clergy"
        clear-button
        @input="saveInput($event.target.value, 'clergy')"
      />

      <f7-list-input
        type="text"
        label="Successor"
        placeholder="Authorized Representative"
        :value="form.successor"
        :default-value="form.successor"
        clear-button
        @input="saveInput($event.target.value, 'successor')"
      />

      <f7-list-input
        type="textarea"
        label="Funeral Music"
        placeholder="Music"
        :value="music"
        :default-value="music"
        resizable
        clear-button
        @input="saveInput($event.target.value, 'music')"
      />

      <f7-list-input
        type="textarea"
        label="Comments"
        placeholder="Comments"
        :value="comments"
        :default-value="comments"
        resizable
        clear-button
        @input="saveInput($event.target.value, 'comments')"
      />
    </f7-list>
  </f7-page>
</template>

<script>
import { getDetails, createDetails, getUser } from '../services/backend';

export default {
  name: 'Details',

  data() {
    return {
      user: null,
      details: null,
      form: {
        uuid: null,
        ceremonyPlace: null,
        restingPlace: null,
        representative: null,
        clergy: null,
        successor: null,
        comments: null,
        music: null,
      },
    };
  },

  computed: {
    comments() {
      return this.form.comments ? this.form.comments.replace(/<br>/gm, '\n') : '';
    },

    music() {
      return this.form.music ? this.form.music.replace(/<br>/gm, '\n') : '';
    },
  },

  created() {
    this.user = getUser();
  },

  // initial
  async mounted() {
    this.$f7.dialog.preloader();
  },

  methods: {
    // after slide in
    async afterIn() {
      this.details = await getDetails({ uuid: this.user.uuid });

      if (this.details) {
        this.form.ceremonyPlace = this.details.ceremony_place;
        this.form.restingPlace = this.details.resting_place;
        this.form.comments = this.details.comments;
        this.form.representative = this.details.legal_representative;
        this.form.clergy = this.details.clergy_representative;
        this.form.successor = this.details.successor;
        this.form.music = this.details.music;
      }

      this.$f7.dialog.close();
    },

    saveInput(value, type) {
      this.loading = true;
      clearTimeout(this._timerId);

      if (type === 'ceremonyPlace') this.form.ceremonyPlace = value;
      if (type === 'restingPlace') this.form.restingPlace = value;
      if (type === 'comments') this.form.comments = value.replace(/(\r\n|\n|\r)/gm, '<br>');
      if (type === 'representative') this.form.representative = value;
      if (type === 'clergy') this.form.clergy = value;
      if (type === 'successor') this.form.successor = value;
      if (type === 'music') this.form.music = value;

      this._timerId = setTimeout(() => this.updateDetails(), 300);
    },

    async updateDetails() {
      this.form.uuid = this.user.uuid;

      await createDetails(this.form);

      this.loading = false;
    },
  },
};
</script>
