<template>
  <div>
    <f7-block v-if="!friends.length">
      <chip text="You have no connections. To send Prayer & Thoughts add your family and friends first. " />
    </f7-block>

    <f7-list
      v-if="friends.length"
      inset
      class="margin-top"
    >
      <f7-list-input
        label="Prayer & Thought"
        type="textarea"
        resizable
        @input="enterMemory"
        @change="enterMemory"
      />
    </f7-list>

    <f7-block v-if="friends.length">
      <f7-block-title>Send to:</f7-block-title>
      <f7-list simple-list>
        <f7-list-item
          v-for="friend in friends"
          :key="friend.id"
        >
          <span>{{ friend.user.name }}</span>
          <f7-toggle @change="checkUser($event, friend)" />
        </f7-list-item>
      </f7-list>
    </f7-block>
  </div>
</template>

<script>
import Chip from '../../components/Chip';

export default {
  name: 'SendPrayers',

  components: {
    Chip,
  },

  props: {
    friends: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      content: null,
    };
  },

  methods: {
    enterMemory({ target }) {
      clearTimeout(this._timerId);

      this.content = target.value.replace(/(\r\n|\n|\r)/gm, '<br>');
    },

    checkUser({ target }, friend) {
      if(this.content) {
        this.emitChange({
          friendId: friend.user.id,
          content: this.content,
          checked: target.checked,
        });
      } else {
        this.$f7.dialog.alert('Please enter a Prayer & Thought', '');
        target.checked = false;
      }
    },

    /**
     * @param friendId
     * @param content
     * @param checked
     */
    emitChange({ friendId, content, checked }) {
      this.$emit('doPrayer',  { friendId, content, checked });
    },
  },
};
</script>
