<template>
  <f7-page
    name="memory"
    class="memoriesPage"
    @page:afterin="afterIn"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>Write your memories</f7-nav-title>
    </f7-navbar>

    <f7-block class="margin-top no-margin-bottom">
      <Chip text="Write your memories from throughout your life. You can choose whom of your Friends and Family will be able to see this." />
    </f7-block>

    <f7-list
      inset
      class="margin-top"
    >
      <f7-list-input
        label="My Memories"
        type="textarea"
        resizable
        :value="memoryValue"
        :default-value="memoryValue"
        @input="enterMemory"
        @change="enterMemory"
      />
    </f7-list>

    <f7-block>
      <f7-progressbar
        v-show="loading"
        infinite
      />
    </f7-block>

    <f7-block
      v-if="memories.length"
      class="margin-top no-margin-bottom"
    >
      <Chip text="Memories from your family & friends" />
    </f7-block>

    <f7-card
      v-for="memory in memories"
      :key="memory.id"
      class="inset wish-card"
    >
      <f7-card-header class="no-border">
        <div class="wish-avatar">
          <img
            slot="media"
            :src="`${memory.user.image || defaultImage}`"
            height="30"
            alt=""
          >
        </div>
        <div class="wish-name">
          Memories from {{ memory.user.name }}
        </div>
        <div class="wish-date">
          {{ memory.created_at }}
        </div>
      </f7-card-header>
      <f7-card-content v-html="memoryContent(memory.content)" />
    </f7-card>
  </f7-page>
</template>

<script>
import { getMemory, createEulogy, getUser, getMemories, getDefaultImage } from '../services/backend';
import Chip from '../components/Chip';

export default {
  name: 'Memories',

  components: {
    Chip,
  },

  data() {
    return {
      loading: false,
      defaultImage: null,
      user: null,
      memory: null,
      memories: [],
    };
  },

  computed: {
    memoryValue() {
      const { eulogy = null } = this.memory || {};

      return eulogy ? eulogy.replace(/<br>/gm, '\n') : '';
    },
  },

  created() {
    this.user = getUser();
    this.defaultImage = getDefaultImage();
  },

  // initial
  async mounted() {
    this.$f7.dialog.preloader();
  },

  methods: {
    daysAgo({ days_ago }) {
      return `${days_ago} day${days_ago > 1 ? 's' : ''} ago`;
    },

    memoryContent(data) {
      return data ? data.replace(/\n/gm, '<br>') : '';
    },

    // after slide in
    async afterIn() {
      await this.getData();

      this.$f7.dialog.close();
    },

    async getData() {
      const { uuid } = this.user;

      const [memoryRest, memoriesRest] = await Promise.all([
        getMemory({ uuid }),
        getMemories({ uuid }),
      ]);

      this.memory = memoryRest;
      this.memories = memoriesRest;

      console.log(this.memories);
    },

    enterMemory({ target }) {
      this.loading = true;
      clearTimeout(this._timerId);

      const value = target.value.replace(/(\r\n|\n|\r)/gm, '<br>');

      if (this.memory) {
        this.memory.eulogy = value;
      } else {
        this.memory = { eulogy: value };
      }

      console.log('enterMemory', this.memory);

      this._timerId = setTimeout(() => {
        this.updateMemory(value);
      }, 300);
    },

    async updateMemory(value) {
      await createEulogy({
        uuid: this.user.uuid,
        value,
      });

      this.loading = false;
    },
  },
};
</script>
