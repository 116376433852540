<template>
  <div>
    <f7-list
      inset
      media-list
    >
      <f7-list-item
        title="Sent Requests"
        group-title
      />
      <f7-list-item
        v-for="request in users"
        :key="request.id"
        after="..."
        :title="request.user.name"
        :subtitle="request.user.location"
        @click="openPopup(request)"
      >
        <img
          slot="media"
          :src="`${request.user.image || defaultImage}`"
          width="30"
          alt=""
        >
      </f7-list-item>
    </f7-list>

    <f7-sheet
      :opened="sheetOpened"
      style="height:auto;"
      swipe-to-close
      backdrop
      @sheet:closed="sheetOpened = false"
    >
      <f7-page-content>
        <f7-block-title large>
          Options
        </f7-block-title>
        <f7-list inset>
          <f7-list-item
            title="Cancel request"
            @click="cancelRequest"
          />
        </f7-list>
      </f7-page-content>
    </f7-sheet>
  </div>
</template>

<script>
import { getDefaultImage } from '../../../services/backend';

export default {
  name: 'SentRequests',

  props: {
    users: {
      type: Array,
      default: () => [],
    },
    domain: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      defaultImage: null,
      sheetOpened: false,
      request: null,
    };
  },

  created() {
    this.defaultImage = getDefaultImage();
  },

  methods: {
    openPopup(request) {
      this.sheetOpened = true;
      this.request = request;
    },

    cancelRequest() {
      const msg = 'Are you sure you want to cancel this request?';
      this.$f7.dialog.confirm(msg, '', () => {
        this.$emit('cancel-friend-request', { id: this.request.id });
        this.sheetOpened = false;
      });
    },
  },
};
</script>
