<template>
  <div>
    <f7-list
      v-for="friend in friends"
      :key="friend.id"
      inset
      class="margin-top"
    >
      <f7-list-item>
        <strong>{{ friend.user.name }}</strong> <small>{{ friend.friend.type || '' }}</small>
      </f7-list-item>

      <f7-list-item :title="friend.user.email" />
      <f7-list-item :title="friend.user.phone" />
      <f7-list-button
        color="blue"
        title="Select Contact"
        @click="selectUser(friend.user.id)"
      />
    </f7-list>

    <f7-list
      v-for="contact in phoneContacts"
      :key="contact.id"
      inset
    >
      <f7-list-item>
        <strong>{{ contact.name }}</strong>
      </f7-list-item>

      <f7-list-item
        v-if="contact.emails.length"
        title="Select an Email Address"
        group-title
      />
      <f7-list-item
        v-for="(email, emailId) in contact.emails"
        :key="email.id"
        radio
        radio-icon="end"
        :title="email.value"
        :value="email.value"
        :name="`${contact.id}-email-radio`"
        :checked="emailId === 0"
        @change="changeEmail($event.target.value, contact.id)"
      />

      <f7-list-item
        v-if="contact.phoneNumbers.length"
        title="Select a Phone Number"
        group-title
      />
      <f7-list-item
        v-for="(phone, phoneId) in contact.phoneNumbers"
        :key="phoneId"
        radio
        radio-icon="end"
        :title="phone"
        :value="phone"
        :name="`${contact.id}-phone-radio`"
        :checked="phoneId === 0"
        @change="changePhone($event.target.value, contact.id)"
      />

      <f7-list-button
        color="blue"
        title="Select Contact"
        @click="importContact(contact.id)"
      />
    </f7-list>
  </div>
</template>

<script>
export default {
  name: 'Contacts',

  props: {
    phoneContacts: {
      type: Array,
      default: () => [],
    },
    friends: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      contactsData: this.phoneContacts,
    };
  },

  methods: {
    importContact(contactId) {
      const { id, email, phone, firstName, middleName, familyName } =
          this.contactsData.find(contact => contact.id === contactId);

      this.$emit('import-contact', {
        id,
        name: `${firstName} ${middleName} ${familyName}`.trim(),
        email,
        phone: phone.replace(/\D/g, ''),
      });
    },

    selectUser(forUserId) {
      this.$emit('select-user', { forUserId });
    },

    changeEmail(value, contactId) {
      const contact = this.contactsData.find(contact => contact.id === contactId);
      if (contact) {
        contact.email = value;
      }
    },

    changePhone(value, contactId) {
      const contact = this.contactsData.find(contact => contact.id === contactId);
      if (contact) {
        contact.phone = value;
      }
    },
  },
};
</script>
