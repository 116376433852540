<template>
  <div>
    <f7-list inset>
      <f7-list-input
        label="First Name"
        type="text"
        :value="form.firstName"
        placeholder="Your first name"
        clear-button
        @input="form.firstName = $event.target.value"
      />
      <f7-list-input
        label="Last Name"
        type="text"
        :value="form.lastName"
        placeholder="Your last name"
        clear-button
        @input="form.lastName = $event.target.value"
      />
      <f7-list-input
        label="City & State"
        type="text"
        :value="form.location"
        placeholder="Your city & state"
        clear-button
        @input="form.location = $event.target.value"
      />
      <f7-list-input
        label="Phone Number (required for verification)"
        type="tel"
        :value="form.phone"
        placeholder="Your phone number"
        clear-button
        @input="form.phone = $event.target.value"
      />
      <f7-list-input
        label="Email"
        type="email"
        :value="form.email"
        placeholder="Your email"
        clear-button
        @input="form.email = $event.target.value"
      />
      <f7-list-input
        label="Password"
        type="password"
        :value="form.password"
        placeholder="Your Password"
        clear-button
        @input="form.password = $event.target.value"
      />
      <f7-list-input
        label="Repeat Password"
        type="password"
        :value="form.confirmPassword"
        placeholder="Repeat Password"
        clear-button
        @input="form.confirmPassword = $event.target.value"
      />
    </f7-list>
    <div class="padding-horizontal padding-bottom">
      <f7-button
        large
        fill
        @click="signUp"
      >
        Sign Up
      </f7-button>
    </div>
    <div class="padding-horizontal padding-bottom">
      <f7-button
        large
        @click="showSignIn"
      >
        Sign In
      </f7-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',

  data() {
    return {
      form: {
        email: null,
        firstName: null,
        lastName: null,
        location: null,
        phone: null,
        password: null,
        confirmPassword: null,
      },
    };
  },

  methods: {
    signUp() {
      if (!this.form.email) {
        this.$f7.dialog.alert('Email address is required', '');
        return;
      }

      if (!this.form.firstName) {
        this.$f7.dialog.alert('First name is required', '');
        return;
      }

      if (!this.form.lastName) {
        this.$f7.dialog.alert('Last name is required', '');
        return;
      }

      if (!this.form.location) {
        this.$f7.dialog.alert('City & State is required', '');
        return;
      }

      if (!this.form.phone) {
        this.$f7.dialog.alert('Phone number is required', '');
        return;
      }

      if (this.form.password !== this.form.confirmPassword) {
        this.$f7.dialog.alert('Passwords don\'t match', '');
        return;
      }

      this.$emit('sign-up', this.form);
    },

    showSignIn() {
      this.$emit('showSignIn');
    },
  },
};
</script>

<style scoped>

</style>
