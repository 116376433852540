import axios from 'axios';

const BASE = 'https://master.massinflux.com';
const BASE_URL = `${BASE}/last-wish`;

/**
 * @returns {string}
 */
export function getBase() {
  return BASE;
}

/**
 * @returns {string}
 */
export function getDomain() {
  return BASE_URL;
}

/**
 * @returns {string}
 */
export function getDefaultImage() {
  return 'static/images/profile_placeholder.jpg';
}

/**
 * @returns {string}
 */
export function getAbout() {
  return localStorage.getItem('lw_about');
}

/**
 * @returns {Promise<void>}
 */
export async function saveAbout() {
  localStorage.setItem('lw_about', 'da');
}

/**
 * @returns {Promise<any>}
 */
export function getUser() {
  const data = localStorage.getItem('lw_user');
  return JSON.parse(data);
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getRealUser({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/user/${uuid}?u=${u}`);

  await saveUser(data);

  return data;
}

/**
 * @param q
 * @param uuid
 * @returns {Promise<any>}
 */
export async function search({ q, uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/search?q=${q}&uuid=${uuid}&u=${u}`);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getImage({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/images/${uuid}?u=${u}`);

  return data;
}

/**
 * @param dir
 * @param name
 * @returns {Promise<any>}
 */
export async function deleteImage({ dir, name }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/image/delete?dir=${dir}&name=${name}&u=${u}`);

  return data;
}

/**
 * @param uuid
 * @param firstName
 * @param middleName
 * @param lastName
 * @param email
 * @param phone
 * @param location
 * @param deceased
 * @param age
 * @returns {Promise<any>}
 */
export async function updateUser({ uuid, firstName, middleName, lastName, email, phone, location, deceased, age }) {
  const q = clean({
    uuid,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    email,
    phone,
    location,
    deceased,
    age,
  });

  const { data } = await axios.post(`${BASE_URL}/user/create`, q);

  const user = await getUser();
  await saveUser({ ...user, ...q });

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function deleteUser({ uuid }) {
  const q = {
    uuid,
  };

  const { data } = await axios.post(`${BASE_URL}/user/delete`, q);

  return data;
}

/**
 * @param user
 * @returns {Promise<void>}
 */
export async function saveUser(user) {
  localStorage.setItem('lw_user', JSON.stringify(user));
}

/**
 * @returns {Promise<void>}
 */
export async function removeUser() {
  localStorage.removeItem('lw_user');
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getMemory({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/eulogy/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @param value
 * @returns {Promise<any>}
 */
export async function createEulogy({ uuid, value }) {
  const q = clean({
    uuid,
    eulogy: value,
  });

  const { data } = await axios.post(`${BASE_URL}/eulogy/create`, q);

  return data;
}

/**
 * @param uuid
 * @param firstName
 * @param middleName
 * @param lastName
 * @param email
 * @param phone
 * @returns {Promise<any>}
 */
export async function createContact({ uuid, firstName, middleName, lastName, email, phone }) {
  const q = clean({
    uuid,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    email,
    phone,
  });

  const { data } = await axios.post(`${BASE_URL}/contact/create`, q);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getContacts({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/contacts/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getDetails({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/details/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @param ceremonyPlace
 * @param restingPlace
 * @param comments
 * @param representative
 * @param authority
 * @param music
 * @param clergy
 * @returns {Promise<any>}
 */
export async function createDetails(
  {
    uuid,
    ceremonyPlace,
    restingPlace,
    comments,
    representative,
    successor,
    music,
    clergy,
  },
) {
  const obj = {
    uuid,
    ceremony_place: ceremonyPlace,
    resting_place: restingPlace,
    legal_representative: representative,
    clergy_representative: clergy,
    successor: successor,
    comments,
    music,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/details/create`, q);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getPrayers({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/prayers/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @param fromUserId
 * @param content
 * @returns {Promise<any>}
 */
export async function createPrayer({ uuid, friendId, content }) {
  const q = clean({
    uuid,
    user_id: friendId,
    content,
  });

  const { data } = await axios.post(`${BASE_URL}/prayers/create`, q);

  return data;
}

/**
 * @param uuid
 * @param id
 * @param content
 * @returns {Promise<any>}
 */
export async function updatePrayer({ uuid, id, content }) {
  const q = clean({
    uuid,
    id,
    content,
  });

  const { data } = await axios.post(`${BASE_URL}/prayers/update`, q);

  return data;
}

/**
 * @param uuid
 * @param id
 * @returns {Promise<any>}
 */
export async function deletePrayer({ uuid, id }) {
  const q = clean({
    uuid,
    id,
  });

  const { data } = await axios.post(`${BASE_URL}/prayers/delete`, q);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getTodos({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/todos/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @param fromUserId
 * @param content
 * @returns {Promise<any>}
 */
export async function createTodo({ uuid, fromUserId, content }) {
  const q = clean({
    uuid,
    from_user_id: fromUserId,
    content,
  });

  const { data } = await axios.post(`${BASE_URL}/todos/create`, q);

  return data;
}

/**
 * @param uuid
 * @param id
 * @param done
 * @param content
 * @returns {Promise<any>}
 */
export async function updateTodo({ uuid, id, done, content }) {
  const q = clean({
    uuid,
    id,
    content,
    done,
  });

  const { data } = await axios.post(`${BASE_URL}/todos/update`, q);

  return data;
}

/**
 * @param uuid
 * @param id
 * @returns {Promise<any>}
 */
export async function deleteTodo({ uuid, id }) {
  const q = clean({
    uuid,
    id,
  });

  const { data } = await axios.post(`${BASE_URL}/todos/delete`, q);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getMemories({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/memories/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @param fromUserId
 * @param content
 * @returns {Promise<any>}
 */
export async function createMemory({ uuid, fromUserId, content }) {
  const q = clean({
    uuid,
    from_user_id: fromUserId,
    content,
  });

  const { data } = await axios.post(`${BASE_URL}/memory/create`, q);

  return data;
}

/**
 * @param uuid
 * @param id
 * @param content
 * @returns {Promise<any>}
 */
export async function updateMemory({ uuid, id, content }) {
  const q = clean({
    uuid,
    id,
    content,
  });

  const { data } = await axios.post(`${BASE_URL}/memory/update`, q);

  return data;
}

/**
 * @param uuid
 * @param id
 * @returns {Promise<any>}
 */
export async function deleteMemory({ uuid, id }) {
  const q = clean({
    uuid,
    id,
  });

  const { data } = await axios.post(`${BASE_URL}/memory/delete`, q);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function getFriends({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/friends/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function friendDetails({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/friend/details/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function friendRequests({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/friend/requests/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function friendRequested({ uuid }) {
  const u = getU();
  const { data } = await axios.get(`${BASE_URL}/friend/requested/${uuid}?u=${u}`);

  return data;
}

/**
 * @param uuid
 * @param friendId
 * @returns {Promise<any>}
 */
export async function friendRequest({ uuid, friendId }) {
  const obj = {
    uuid,
    friend_id: friendId,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/friend/request`, q);

  return data;
}

/**
 * @param uuid
 * @returns {Promise<any>}
 */
export async function wishesRequested({ uuid }) {
  const zone = getZone();
  const u = getU();

  const { data } = await axios.get(`${BASE_URL}/wishes/${uuid}?zone=${zone}&u=${u}`);

  return data;
}

/**
 * @param id
 * @returns {Promise<any>}
 */
export async function wishRequested({ id }) {
  const zone = getZone();
  const u = getU();

  const { data } = await axios.get(`${BASE_URL}/wish/${id}?zone=${zone}&u=${u}`);

  return data;
}

/**
 * @param id
 * @returns {Promise<any>}
 */
export async function deleteWistRequest({ id }) {
  const obj = {
    id,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/wishes/delete`, q);

  return data;
}

/**
 * @param uuid
 * @param forUserId
 * @param name
 * @param email
 * @param phone
 * @returns {Promise<any>}
 */
export async function createWistRequest({ uuid, forUserId, name, email, phone }) {
  const obj = {
    uuid,
    for_user_id: forUserId,
    name,
    email,
    phone,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/wishes/create`, q);

  return data;
}

/**
 * @param obj
 * @returns {Promise<any>}
 */
export async function updateWistRequest(obj) {
  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/wishes/update`, q);

  return data;
}

/**
 * @param id
 * @param uuid
 * @returns {Promise<any>}
 */
export async function cancelFriendRequest({ id, uuid }) {
  const obj = {
    id,
    uuid,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/friend/cancel/request`, q);

  return data;
}

/**
 * @param id
 * @param uuid
 * @returns {Promise<any>}
 */
export async function removeFriendRequest({ id, uuid }) {
  const obj = {
    id,
    uuid,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/friend/remove/request`, q);

  return data;
}

/**
 * @param id
 * @param trusted
 * @param shareDetails
 * @param shareWishes
 * @param shareTodos
 * @param uuid
 * @returns {Promise<any>}
 */
export async function updateFriendRequest({ id, trusted, shareDetails, shareWishes, shareTodos, uuid }) {
  const obj = {
    id,
    trusted,
    uuid,
    share_details: shareDetails,
    share_wishes: shareWishes,
    share_todos: shareTodos,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/friend/update/request`, q);

  return data;
}

/**
 * @param id
 * @param type
 * @param uuid
 * @returns {Promise<any>}
 */
export async function updateFriendTypeRequest({ id, type, uuid }) {
  const obj = {
    id,
    type,
    uuid,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/friend/update/request`, q);

  return data;
}

/**
 * @param uuid
 * @param deceased
 * @returns {Promise<any>}
 */
export async function updateDeathRequest({ uuid, deceased }) {
  const obj = { uuid, deceased };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/user/deceased`, q);

  return data;
}

/**
 * @param id
 * @param uuid
 * @returns {Promise<any>}
 */
export async function acceptFriendRequest({ id, uuid }) {
  const obj = {
    id,
    uuid,
  };

  const q = clean(obj);

  const { data } = await axios.post(`${BASE_URL}/friend/accept/request`, q);

  return data;
}

/**
 * @param email
 * @param password
 * @param firstName
 * @param lastName
 * @param phone
 * @param location
 * @returns {Promise<any>}
 */
export async function register({ email, password, firstName, lastName, phone, location }) {
  const q = clean({
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    phone,
    location,
  });

  const { data } = await axios.post(`${BASE_URL}/register`, q);

  return data;
}

/**
 * @param email
 * @param password
 * @returns {Promise<*>}
 */
export async function login({ email, password }) {
  const q = clean({
    email,
    password,
  });

  const { data } = await axios.post(`${BASE_URL}/login`, q);

  return data;
}

/**
 * @param contacts
 * @param activeUsers
 * @returns {*}
 */
export function removeContactDupes(contacts, activeUsers) {
  return contacts.filter(contact => {
    const contactEmail = contact.email;
    const contactPhone = parseInt(contact.phone.replace(/\D/g, ''));

    const resp = activeUsers.find(({ email, phone }) => {
      const dupeEmail = email;
      const dupePhone = parseInt(phone.replace(/\D/g, ''));

      return contactEmail === dupeEmail || contactPhone === dupePhone;
    }) || null;

    return resp === null;
  });
}

/**
 * @param obj
 * @returns {{}}
 */
function clean(obj) {
  const newObj = {};

  for (let key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && (obj[key] !== null && obj[key] !== undefined)) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

/**
 * @returns {string}
 */
function getZone() {
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return encodeURIComponent(zone);
}

/**
 * @returns {number}
 */
export function getU() {
  return new Date().getTime();
}
