<template>
  <f7-page
    name="wishes"
    class="wishesPage"
    @page:afterin="afterIn"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>My Wishes</f7-nav-title>
    </f7-navbar>

    <f7-block class="margin-top no-margin-bottom">
      <Chip text="Here you can add all your wishes." />
    </f7-block>

    <div
      v-for="wish in wishes"
      :key="`wish-${wish.id}`"
    >
      <Wish
        :wish="wish"
        :default-image="defaultImage"
      />
    </div>

    <f7-popup :opened="sheetOpened">
      <f7-page>
        <f7-navbar title="New Wish">
          <f7-nav-right>
            <f7-link @click="sheetOpened = !sheetOpened">
              Close
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <Friends
          :friends="friends"
          @select-user="selectUser"
          @go-to-connections="goToConnections"
        />
      </f7-page>
    </f7-popup>

    <f7-fab
      slot="fixed"
      position="right-bottom"
      text="New wish"
      @click="sheetOpened = true"
    >
      <f7-icon
        ios="f7:plus"
        aurora="f7:plus"
        md="material:add"
      />
    </f7-fab>
  </f7-page>
</template>

<script>
import { createWistRequest, getDefaultImage, getFriends, getUser, wishesRequested } from '../services/backend';
import Wish from '../components/wishes/Wish';
import Friends from '../components/wishes/Friends';
import Chip from '../components/Chip';

export default {
  name: 'Wishes',

  components: {
    Friends,
    Wish,
    Chip,
  },

  data() {
    return {
      defaultImage: null,
      user: null,
      sheetOpened: false,
      friends: [],
      wishes: [],
    };
  },

  async mounted() {
    this.$f7.dialog.preloader();
  },

  created() {
    this.defaultImage = getDefaultImage();
  },

  methods: {
    async afterIn() {
      this.user = await getUser();

      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async getRequestData() {
      const { uuid } = this.user;

      const [friendsResp, wishesResp] = await Promise.all([
        await getFriends({ uuid }),
        await wishesRequested({ uuid }),
      ]);

      this.friends = friendsResp;
      this.wishes = wishesResp;

      console.log(this.wishes);
    },

    async selectUser(data) {
      const wishId = await createWistRequest({ uuid: this.user.uuid, ...data });

      this.sheetOpened = false;

      this.$f7router.navigate('/wishes-add/', {
        props: {
          wishId,
        },
      });

      return false;
    },


    goToConnections() {
      this.sheetOpened = false;

      this.$f7router.navigate('/connections/');

      return false;
    },
  },
};
</script>
