<template>
  <f7-page
    name="documents"
    class="documentsPage"
    @page:afterin="afterIn"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>Funeral Planning Checklist</f7-nav-title>
    </f7-navbar>

    <f7-block class="margin-top">
      <f7-button
        fill
        large
        @click="downloadDocument"
      >
        Download Funeral Planning Guide
      </f7-button>
    </f7-block>

    <f7-block class="margin-top no-margin-bottom">
      <Chip text="Add TODOs and plan your funeral together with your friends & family" />
    </f7-block>

    <TodoItem
      v-for="todo in todos.mine"
      :key="todo.id"
      :todo="todo"
      @remove-todo="removeTodo"
      @toggle-todo="toggleTodo"
    />

    <div
      v-for="(others, othersIndex) in todos.others"
      :key="othersIndex"
    >
      <div
        v-for="(other, i) in others"
        :key="i"
      >
        <f7-block>Shared with you from {{ other.from_user.name }}</f7-block>
        <TodoItem
          v-for="todo in other.todos"
          :key="todo.id"
          :todo="todo"
          @remove-todo="removeTodo"
          @toggle-todo="toggleTodo"
          :disabled="true"
        />
      </div>
    </div>

    <f7-messagebar
      ref="messagebar"
      placeholder="Add todo"
    >
      <f7-link
        slot="inner-end"
        icon-ios="f7:arrow_up_fill"
        icon-aurora="f7:arrow_up_fill"
        icon-md="material:send"
        @click="addToDo"
      />
    </f7-messagebar>
  </f7-page>
</template>

<script>
import { getTodos, deleteTodo, createTodo, updateTodo, getBase, getUser } from '../services/backend';
import Chip from '../components/Chip';
import TodoItem from '../components/documents/TodoItem';

export default {
  name: 'Documents',

  components: {
    Chip,
    TodoItem,
  },

  data() {
    return {
      todos: [],
      messagebar: null,
    };
  },

  created() {
    this.user = getUser();
  },

  // initial
  async mounted() {
    this.$f7.dialog.preloader();
  },

  methods: {
    async afterIn() {
      await this.getTodosList();

      this.$f7.dialog.close();
    },

    downloadDocument() {
      const base = getBase();

      window.open(`${base}/downloads/funeral-guide.pdf`);
    },

    async addToDo() {
      this.messagebar = this.$refs.messagebar.f7Messagebar;
      const content = this.messagebar.getValue().replace(/(\r\n|\n|\r)/gm, '<br>').trim();
      if (!content.length) {
        return;
      }

      this.messagebar.clear();

      await createTodo({
        uuid: this.user.uuid,
        content,
      });

      await this.getTodosList();
    },

    async getTodosList() {
      this.todos = await getTodos({ uuid: this.user.uuid });
      console.log(this.todos);
    },

    async removeTodo(todo) {
      this.$f7.dialog.preloader();

      await deleteTodo({ uuid: this.user.uuid, id: todo.id });

      await this.getTodosList();

      this.$f7.dialog.close();
    },

    async toggleTodo(data) {
      await updateTodo({ uuid: this.user.uuid, id: data.id, done: data.done });
    },
  },
};
</script>
