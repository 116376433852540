<template>
  <div>
    <f7-list inset>
      <f7-list-input
        type="select"
        label="Relation type"
        @change="typeSelect"
      >
        <option value="">
          Select person type
        </option>
        <option
          v-for="(option, optionIndex) in options"
          :key="optionIndex"
          :value="option.type"
          :selected="option.selected"
        >
          {{ option.type }}
        </option>
      </f7-list-input>
    </f7-list>

    <f7-list inset>
      <f7-list-item>
        <span>Set as trusted source</span>
        <f7-toggle
          :checked="request.trusted === 'yes'"
          @change="toggleTrustedRequest"
        />
      </f7-list-item>
      <f7-list-item>
        <span>Share funeral details</span>
        <f7-toggle
          :checked="request.share_details === 'yes'"
          @change="toggleShareDetailsRequest"
        />
      </f7-list-item>
      <f7-list-item>
        <span>Share wishes</span>
        <f7-toggle
          :checked="request.share_wishes === 'yes'"
          @change="toggleShareWishesRequest"
        />
      </f7-list-item>
      <f7-list-item>
        <span>Share todos</span>
        <f7-toggle
          :checked="request.share_todos === 'yes'"
          @change="toggleShareTodosRequest"
        />
      </f7-list-item>
    </f7-list>

    <f7-list inset>
      <f7-list-item
        v-if="friend.trusted === 'yes'"
        class="list-warning"
      >
        <span>Mark as deceased</span>
        <f7-toggle
          :checked="user.deceased === 'yes'"
          @change="toggleDeceasedRequest"
        />
      </f7-list-item>
    </f7-list>

    <f7-list inset>
      <f7-list-item
        class="list-danger"
        :title="`Remove ${user.name}`"
        @click="removeRequest"
      />
    </f7-list>
  </div>
</template>

<script>
export default {
  name: 'Options',

  props: {
    request: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      user: this.request.user,
      friend: this.request.friend,
      optionData: [
        {
          type: 'Aunt',
          selected: false,
        },
        {
          type: 'Friend',
          selected: false,
        },
        {
          type: 'Mother',
          selected: false,
        },
        {
          type: 'Father',
          selected: false,
        },
        {
          type: 'Sister',
          selected: false,
        },
        {
          type: 'Brother',
          selected: false,
        },
        {
          type: 'Cousin',
          selected: false,
        },
        {
          type: 'Grandmother',
          selected: false,
        },
        {
          type: 'Grandfather',
          selected: false,
        },
        {
          type: 'Mother-in-law',
          selected: false,
        },
        {
          type: 'Father-in-law',
          selected: false,
        },
        {
          type: 'Uncle',
          selected: false,
        },
        {
          type: 'Son',
          selected: false,
        },
        {
          type: 'Daughter',
          selected: false,
        },
        {
          type: 'Other',
          selected: false,
        },
      ],
    };
  },

  computed: {
    options() {
      return this.optionData.map(option => {
        option.selected = option.type === this.request.type;
        return option;
      });
    },
  },

  methods: {
    removeRequest() {
      this.$emit('remove', { id: this.request.id });
    },

    typeSelect({ target }) {
      this.$emit('update-friend-type', {
        id: this.request.id,
        type: target.value,
      });
    },

    toggleTrustedRequest(e) {
      this.$emit('toggle-trusted', {
        id: this.request.id,
        trusted: e.target.checked ? 'yes' : 'no',
      });
    },

    toggleShareDetailsRequest(e) {
      this.$emit('toggle-share-details', {
        id: this.request.id,
        shareDetails: e.target.checked ? 'yes' : 'no',
      });
    },

    toggleShareWishesRequest(e) {
      this.$emit('toggle-share-wishes', {
        id: this.request.id,
        shareWishes: e.target.checked ? 'yes' : 'no',
      });
    },

    toggleShareTodosRequest(e) {
      this.$emit('toggle-share-todos', {
        id: this.request.id,
        shareTodos: e.target.checked ? 'yes' : 'no',
      });
    },

    toggleDeceasedRequest(e) {
      this.$emit('toggle-deceased', {
        uuid: this.user.uuid,
        deceased: e.target.checked ? 'yes' : 'no',
      });
    },
  },
};
</script>
