<template>
  <f7-card
    class="inset margin-bottom wish-card"
  >
    <f7-card-header class="no-border">
      <div class="wish-avatar">
        <img
          :src="wish.image || wish.user.image || defaultImage"
          alt=""
          width="34"
          height="34"
        >
      </div>
      <div class="wish-name">
        {{ name }}
      </div>
      <div class="wish-date">
        {{ wish.date }}
      </div>
    </f7-card-header>
    <f7-card-content>
      <p
        v-if="wish.details"
        v-html="wish.details"
      />
      <p v-else>
        Empty wish
      </p>

      <Images
        v-if="wish.images.length"
        :images="wish.images"
      />
    </f7-card-content>
    <f7-card-footer class="no-border">
      <f7-link />
      <f7-link />
      <f7-link @click="goToWishesAd(wish.id)">
        Edit Wish
        <f7-icon
          f7="chevron_right"
          class="small-arrow"
        />
      </f7-link>
    </f7-card-footer>
  </f7-card>
</template>

<script>
import Images from '../../components/wishes/Images';

export default {
  name: 'Wish',

  components: {
    Images,
  },

  props: {
    wish: {
      type: Object,
      required: true,
    },
    defaultImage: {
      type: String,
      required: true,
    },
  },

  computed: {
    name() {
      const { name = null, user } = this.wish || {};

      return name || user.name;
    },
  },

  methods: {
    goToWishesAd(wishId) {
      this.$f7router.navigate('/wishes-add/', {
        props: {
          wishId,
        },
      });
    },
  },
};
</script>
