<template>
  <div>
    <f7-block-title large>
      Sign In
    </f7-block-title>
    <f7-list>
      <f7-list-input
        label="Email"
        type="email"
        :value="form.email"
        placeholder="Your email"
        clear-button
        @input="form.email = $event.target.value"
      />
      <f7-list-input
        label="Password"
        type="password"
        :value="form.password"
        placeholder="Your Password"
        clear-button
        @input="form.password = $event.target.value"
      />
    </f7-list>
    <div class="padding-horizontal padding-bottom">
      <f7-button
        large
        fill
        @click="signIn"
      >
        Sign In
      </f7-button>
    </div>
    <div class="padding-horizontal padding-bottom">
      <f7-button
        large
        @click="showSignUp"
      >
        Sign Up
      </f7-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    showSignUp() {
      this.$emit('showSignUp');
    },

    signIn() {
      this.$emit('signIn', this.form);
    },
  },
};
</script>

<style scoped>

</style>
