<template>
  <f7-page name="signUp">
    <f7-navbar back-link="Back">
      <f7-nav-title>Sign Up</f7-nav-title>
    </f7-navbar>

    <Register
      @showSignIn="goToSignIn"
      @sign-up="signUp"
    />
  </f7-page>
</template>

<script>
import Register from '../components/auth/Register';
import { login, register, saveUser } from '../services/backend';

export default {
  name: 'SignUp',

  components: {
    Register,
  },

  methods: {
    async signUp(form) {
      this.$f7.preloader.show();

      const resp = await register(form);

      if (resp.error) {
        this.$f7.dialog.alert(resp.error, '');
      } else {
        const user = await login(form);

        await saveUser(user);
      }

      this.$f7.preloader.hide();

      this.goToSignIn();
    },

    goToSignIn() {
      this.$f7router.navigate('/');

      return false;
    },
  },
};
</script>
