<template>
  <f7-page
    name="profile"
    class="profilePage"
    @page:afterin="afterIn"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>Oveey</f7-nav-title>
    </f7-navbar>

    <f7-block
      :key="imageKey"
      class="text-align-center no-margin-bottom margin-top"
    >
      <div
        :style="`background-image: url('${profileImage}?${imageKey}')`"
        class="profile-image"
      />
    </f7-block>

    <f7-block class="no-margin-top no-margin-bottom">
      <ImageUpload
        :dir="`${userId}/profile`"
        file-name="user"
        title="Upload profile photo"
        @upload-completed="uploadCompleted"
      />
    </f7-block>

    <f7-list
      v-if="user"
      class="no-margin-top no-margin-bottom"
      inset
    >
      <f7-list-input
        type="text"
        label="First Name"
        placeholder="First Name"
        :default-value="user.first_name || ''"
        clear-button
        @input="saveInput($event.target.value, 'firstName')"
      />
      <f7-list-input
        type="text"
        label="Middle Name"
        placeholder="Middle Name"
        :default-value="user.middle_name || ''"
        clear-button
        @input="saveInput($event.target.value, 'middleName')"
      />
      <f7-list-input
        type="text"
        label="Last Name"
        placeholder="Last Name"
        :default-value="user.last_name || ''"
        clear-button
        @input="saveInput($event.target.value, 'lastName')"
      />
      <f7-list-input
        label="Default setup"
        type="datepicker"
        placeholder="Your birth date"
        :value="user.age ? [new Date(user.age)] : ''"
        readonly
        @change="saveInput($event.target.value, 'age')"
      />
      <f7-list-input
        type="text"
        label="City & State"
        placeholder="City & State"
        :default-value="user.location || ''"
        clear-button
        @input="saveInput($event.target.value, 'location')"
      />
      <f7-list-input
        type="email"
        label="Email Address"
        placeholder="Email Address"
        :default-value="user.email || ''"
        clear-button
        @input="saveInput($event.target.value, 'email')"
      />
      <f7-list-input
        type="tel"
        label="Phone Number"
        placeholder="Phone Number"
        :default-value="user.phone || ''"
        clear-button
        @input="saveInput($event.target.value, 'phone')"
      />
      <!--f7-list-item>
        <span>Mark as deceased</span>
        <f7-toggle :checked="user.deceased === 'yes'" @change="toggleDeathRequest"/>
      </f7-list-item-->
    </f7-list>

    <f7-block>
      <f7-button
        fill
        large
        color="red"
        @click="closeAccount"
      >
        Close my account
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import { updateUser, getUser, getRealUser, getImage, getDefaultImage, removeUser, deleteUser } from '../services/backend';
import ImageUpload from '../components/ImageUpload';

export default {
  name: 'Profile',

  components: {
    ImageUpload,
  },

  data() {
    return {
      imageKey: 0,
      image: null,
      user: null,
      form: {
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
        phone: null,
        location: null,
        deceased: null,
        age: null,
      },
    };
  },

  computed: {
    userId() {
      return this.user ? this.user.id : '';
    },

    profileImage() {
      const defaultImage = getDefaultImage();

      return this.image || defaultImage;
    },
  },

  async mounted() {
    this.$f7.dialog.preloader();

    this.user = getUser();
    this.user = await getRealUser({ uuid: this.user.uuid });

    await this.getImg();
  },

  methods: {
    async afterIn() {
      await this.getImg();

      this.$f7.dialog.close();
    },

    async uploadCompleted({ image }) {
      this.image = image;

      await this.getImg();
    },

    async getImg() {
      this.image = await getImage({ uuid: this.user.uuid });
      this.imageKey++;
    },

    /*
    toggleDeathRequest(e) {
      const value = e.target.checked ? 'yes' : 'no';
      const type = value === 'yes' ? '' : 'not';
      const text = `Are you sure you want to mark yourself as ${type} deceased?`;

      this.$f7.dialog.confirm(text, '', () => {
        this.saveInput(value, 'deceased');
      });
    },
    */

    async saveInput(value, type) {
      clearTimeout(this._timerId);

      this.form = this.user;

      if (type === 'firstName') this.form.firstName = value;
      if (type === 'middleName') this.form.middleName = value;
      if (type === 'lastName') this.form.lastName = value;
      if (type === 'email') this.form.email = value;
      if (type === 'phone') this.form.phone = value;
      if (type === 'location') this.form.location = value;
      if (type === 'deceased') this.form.deceased = value;
      if (type === 'age') this.form.age = value;

      const { email = null, phone = null } = this.form || {};

      if (email === '' || email === null) {
        this.$f7.dialog.alert('An Email Address is required', '');
        return;
      }

      if (phone === '' || phone === null) {
        this.$f7.dialog.alert('A Phone Number is required', '');
        return;
      }

      this._timerId = setTimeout(() => {
        this.updateDetails();
      }, 300);
    },

    async updateDetails() {
      this.form.uuid = this.user.uuid;

      await updateUser(this.form);

      this.loading = false;
    },

    async closeAccount() {
      const msg = 'This action will remove your account and all your data. Proceed?';
      this.$f7.dialog.confirm(msg, '', async() => {
        try {
          await deleteUser(this.user);
        } catch (e) {
          // console.log(e);
        }

        await removeUser();

        this.$f7router.navigate('/');
      });
    },
  },
};
</script>
