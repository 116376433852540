<template>
  <div>
    <Friend
      v-for="userData in users"
      :key="userData.id"
      :user-data="userData"
      @add-memories="addMemories"
      @open-popup="openPopup"
    />
    <div style="height: 100px;" />

    <f7-sheet
      :opened="sheetOpened"
      style="height:auto;"
      swipe-to-close
      backdrop
      @sheet:closed="sheetOpened = false"
    >
      <f7-page-content>
        <f7-block-title large>
          Options
        </f7-block-title>
        <Options
          v-if="request"
          :request="request"
          :type="type"
          @remove="removeRequest"
          @toggle-trusted="toggleTrustedRequest"
          @toggle-share-details="toggleShareDetailsRequest"
          @toggle-share-wishes="toggleShareWishesRequest"
          @toggle-share-todos="toggleShareTodosRequest"
          @toggle-deceased="toggleDeceasedRequest"
          @update-friend-type="updateFriendType"
        />
      </f7-page-content>
    </f7-sheet>
  </div>
</template>

<script>
import Options from '../../components/connections/Options';
import Friend from '../../components/connections/Friend';
import { createMemory } from '../../services/backend';

const YES = 'yes';

export default {
  name: 'Friends',

  components: {
    Options,
    Friend,
  },

  props: {
    users: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      id: null,
      trusted: false,
      sheetOpened: false,
      request: null,
    };
  },

  methods: {
    openPopup(request) {
      this.sheetOpened = true;
      this.request = request;
    },

    removeRequest({ id }) {
      const msg = 'Are you sure you want to remove this person?';
      this.$f7.dialog.confirm(msg, '', () => {
        this.$emit('remove-friend-request', { id });
        this.sheetOpened = false;
      });
    },

    toggleTrustedRequest({ id, trusted }) {
      this.$emit('toggle-trusted-request', { id, trusted });
    },

    toggleShareDetailsRequest({ id, shareDetails }) {
      this.$emit('toggle-share-details-request', { id, shareDetails });
    },

    toggleShareWishesRequest({ id, shareWishes }) {
      this.$emit('toggle-share-wishes-request', { id, shareWishes });
    },

    toggleShareTodosRequest({ id, shareTodos }) {
      this.$emit('toggle-share-todos-request', { id, shareTodos });
    },

    toggleDeceasedRequest({ uuid, deceased }) {
      const name = this.getName(this.user);
      const type = deceased === YES ? '' : 'not';
      const text = `Are you sure you want to mark ${name} as ${type} deceased?`;

      this.$f7.dialog.confirm(text, '', () => {
        this.$emit('toggle-deceased-request', { uuid, deceased });
      });
    },

    updateFriendType({ id, type }) {
      this.$emit('update-friend-type', { id, type });
    },

    async addMemories(form) {
      await createMemory(form);
    },
  },
};
</script>
