<template>
  <div>
    <f7-searchbar
      placeholder="Search by email"
      :clear-button="true"
      @input="initSearch"
      @click:clear="cleanResults"
      @click:disable="cleanResults"
    />

    <div
      v-if="!users.length"
      style="height: 100%"
      class="display-flex justify-content-center align-items-center"
    >
      <p>Try searching for an email</p>
    </div>

    <f7-list
      inset
      media-list
      class="margin-top-half"
    >
      <f7-list-item
        v-for="userData in users"
        :key="userData.id"
        after="Connect"
        link="#"
        :title="name(userData)"
        :subtitle="userData.location"
        @click="sendFriendRequest({ id: userData.id })"
      >
        <img
          v-if="userData.image"
          slot="media"
          :src="`${domain}${userData.image}`"
          width="30"
          alt=""
        >
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
export default {
  name: 'Search',

  props: {
    users: {
      type: Array,
      default: () => [],
    },
    domain: {
      type: String,
      default: '',
    },
  },

  methods: {
    initSearch(e) {
      clearTimeout(this._timerId);

      this._timerId = setTimeout(() => {
        this.searchInput(e.target.value);
      }, 200);
    },

    searchInput(value) {
      if (value.length > 2) {
        this.$emit('search-users', { value });
      }
    },

    sendFriendRequest({ id }) {
      this.$emit('friend-request', { friendId: id });
    },

    cleanResults() {
      this.$emit('clear-results');
    },

    name({ first_name, middle_name, last_name, email }) {
      const name = `${first_name || ''} ${middle_name || ''} ${last_name || ''}`.trim();

      return name !== '' ? name : email;
    },
  },
};
</script>

<style scoped>

</style>
