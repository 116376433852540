<template>
  <f7-page
    name="connectionDetails"
    class="connectionsDetailsPage"
    @page:afterin="afterIn"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>Wishes & Details</f7-nav-title>
    </f7-navbar>

    <f7-card
      v-if="item"
      class="inset wish-card"
    >
      <f7-card-header class="no-border">
        <div class="wish-avatar">
          <img
            slot="media"
            :src="`${item.image || defaultImage}`"
            width="30"
            alt=""
          >
        </div>
        <div class="wish-name">
          {{ name }}
        </div>
        <div class="wish-date">
          {{ item.created_at }}
        </div>
      </f7-card-header>
      <f7-card-content>
        <f7-list v-if="shareDetails === 'yes' && details.length">
          <f7-list-item
            v-for="(detail, detailIndex) in details"
            :key="detailIndex"
            :header="detail.header"
          >
            <div
              slot="title"
              v-html="detail.value"
            />
          </f7-list-item>
        </f7-list>

        <div v-else>
          <div v-if="shareDetails === 'no'">
            You don't have permission to view the Ceremony & Resting place details.
          </div>
          <div v-if="!details.length">
            There are no Ceremony & Resting place details added.
          </div>
        </div>
      </f7-card-content>
    </f7-card>

    <f7-card
      v-if="item && wishes"
      class="inset wish-card"
    >
      <f7-card-header class="no-border">
        <div class="wish-avatar">
          <img
            slot="media"
            :src="`static/images/wishes.png`"
            width="30"
            alt=""
          >
        </div>
        <div class="wish-name">
          Wishes
        </div>
        <div class="wish-date">
          Shared Wishes
        </div>
      </f7-card-header>
      <f7-card-content>
        <f7-list
          v-if="wishes.length"
          media-list
        >
          <f7-list-item
            v-for="wish in wishes"
            :key="wish.id"
            class="wish-details"
            :title="wish.name || '-'"
          >
            <img
              slot="media"
              :src="`${wish.images.length ? wish.image[0] : defaultImage}`"
              width="80"
              alt=""
            >

            <div
              slot="text"
              v-html="wish.details"
            />
          </f7-list-item>
        </f7-list>

        <div v-else>
          No wishes added
        </div>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
import { friendDetails } from '../services/backend';
import { getDefaultImage } from '../services/backend';

export default {
  name: 'ConnectionDetails',

  props: {
    uuid: {
      type: String,
      required: true,
    },
    shareDetails: {
      type: String,
      required: true,
    },
    sharWishes: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      defaultImage: null,
      item: null,
      details: [],
      wishes: [],
    };
  },

  computed: {
    name() {
      const { w_name = null, first_name = null, middle_name = null, last_name = null } = this.item || {};

      if (w_name) {
        return w_name;
      }

      return `${first_name || ''} ${middle_name || ''} ${last_name || ''}`;
    },
  },

  created() {
    this.defaultImage = getDefaultImage();
  },

  async mounted() {
    this.$f7.dialog.preloader();
  },

  methods: {
    async afterIn() {
      const [friendDetailsResp] = await Promise.all([
        friendDetails({ uuid: this.uuid }),
      ]);

      this.item = friendDetailsResp;

      this.getDetails();
      this.getWishes();

      this.$f7.dialog.close();
    },

    getWishes() {
      this.wishes = this.item.wishes;
    },

    getDetails() {
      const details = this.item.details ? [
        {
          header: 'Ceremony Place',
          value: this.item.details.ceremony_place,
        },
        {
          header: 'Resting Place',
          value: this.item.details.resting_place,
        },
        {
          header: 'Clergy Representative',
          value: this.item.details.clergy_representative,
        },
        {
          header: 'Legal Representative',
          value: this.item.details.legal_representative,
        },
        {
          header: 'Successor',
          value: this.item.details.successor,
        },
        {
          header: 'Funeral Music',
          value: this.item.details.music,
        },
        {
          header: 'Comments',
          value: this.item.details.comments,
        },
      ] : [];

      this.details = details.filter(entry => entry.value !== null && entry.value !== '');
    },
  },
};
</script>
