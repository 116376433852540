<template>
  <f7-page
    :page-content="false"
    name="connections"
    class="connectionsPage"
    @page:afterin="afterIn"
    @page:beforeremove="onPageBeforeRemove"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>Family & Friends</f7-nav-title>
    </f7-navbar>

    <f7-toolbar
      tabbar
      bottom
    >
      <f7-link
        tab-link="#tab-1"
        tab-link-active
      >
        {{ friendsCount }}
      </f7-link>
      <f7-link tab-link="#tab-2">
        {{ requestCount }}
      </f7-link>
    </f7-toolbar>

    <f7-tabs animated>
      <f7-tab
        id="tab-1"
        class="page-content"
        tab-active
      >
        <PendingRequests
          v-if="requestUsers.length"
          :key="1"
          :users="requestUsers"
          type="request"
          @accept-friend-request="acceptFriendRequest"
          @deny-friend-request="cancelFriendRequest"
        />

        <Friends
          v-if="activeUsers.length"
          :key="2"
          :users="activeUsers"
          type="active"
          @toggle-deceased-request="toggleDeceasedRequest"
          @toggle-trusted-request="toggleTrustedRequest"
          @toggle-share-details-request="toggleShareDetailsRequest"
          @toggle-share-wishes-request="toggleShareWishesRequest"
          @toggle-share-todos-request="toggleShareTodosRequest"
          @remove-friend-request="removeFriendRequest"
          @update-friend-type="updateFriendTypeRequest"
        />

        <f7-list
          v-else
          inset
          media-list
        >
          <f7-list-item
            title="My Family & Friends"
            group-title
          />
          <f7-list-item title="Start by searching below." />
        </f7-list>
      </f7-tab>
      <f7-tab
        id="tab-2"
        class="page-content"
      >
        <SentRequests
          v-if="pendingUsers.length"
          :key="3"
          :users="pendingUsers"
          @cancel-friend-request="cancelFriendRequest"
        />

        <f7-list
          v-else
          inset
          media-list
        >
          <f7-list-item
            title="Sent Requests"
            group-title
          />
          <f7-list-item title="No Sent Requests" />
        </f7-list>
      </f7-tab>
    </f7-tabs>

    <div>
      <f7-fab
        slot="fixed"
        position="right-bottom"
        text="Add Family & Friends"
        @click="sheetOpened = true"
      />
    </div>

    <f7-popup :opened="sheetOpened">
      <f7-page class="homePage">
        <f7-navbar title="Search people">
          <f7-nav-right>
            <f7-link @click="sheetOpened = !sheetOpened">
              Close
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <search
          :users="users"
          @search-users="searchUsers"
          @clear-results="clearResults"
          @friend-request="sendFriendRequest"
        />
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import {
  acceptFriendRequest,
  cancelFriendRequest,
  updateFriendRequest,
  updateFriendTypeRequest,
  removeFriendRequest,
  updateDeathRequest,
  friendRequest,
  friendRequested,
  friendRequests,
  getFriends,
  getUser,
  search,
} from '../services/backend';

import Search from '../components/connections/Search';
import SentRequests from '../components/connections/Requests/SentRequests';
import PendingRequests from '../components/connections/Requests/PendingRequests';
import Friends from '../components/connections/Friends';

export default {
  name: 'Connections',

  components: {
    SentRequests,
    PendingRequests,
    Search,
    Friends,
  },

  data() {
    return {
      users: [],
      user: null,
      sheetOpened: false,
      contactsSheetOpened: false,
      activeUsers: [],
      pendingUsers: [],
      requestUsers: [],
    };
  },

  computed: {
    friendsCount() {
      const l = this.activeUsers.length;
      if (l === 0) return 'My Friends';

      return l === 1 ? '1 Friend' : `${l} Friends`;
    },

    requestCount() {
      const l = this.pendingUsers.length;

      if (l === 0) return 'No Requests';

      return l === 1 ? '1 Request' : `${l} Requests`;
    },
  },

  created() {
    this.user = getUser();
  },

  async mounted() {
    this.$f7.dialog.preloader();
  },

  methods: {
    async afterIn() {
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    /**
     * @returns {Promise<void>}
     */
    async getRequestData() {
      const { uuid } = this.user;

      const [
        friendRequestsResp,
        friendRequestedResp,
        friendsResp,
      ] = await Promise.all([
        friendRequests({ uuid }),
        friendRequested({ uuid }),
        getFriends({ uuid }),
      ]);

      this.activeUsers = friendsResp;
      this.pendingUsers = friendRequestsResp;
      this.requestUsers = friendRequestedResp;
    },

    async searchUsers({ value }) {
      this.users = await search({ q: value, uuid: this.user.uuid });
    },

    async clearResults() {
      this.users = [];
    },

    async cancelFriendRequest({ id }) {
      this.$f7.dialog.preloader();

      await cancelFriendRequest({ id, uuid: this.user.uuid });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async removeFriendRequest({ id }) {
      this.$f7.dialog.preloader();

      await removeFriendRequest({ id, uuid: this.user.uuid });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async acceptFriendRequest({ id }) {
      this.$f7.dialog.preloader();

      await acceptFriendRequest({ id, uuid: this.user.uuid });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async toggleTrustedRequest({ id, trusted }) {
      this.$f7.dialog.preloader();

      await updateFriendRequest({ id, trusted, uuid: this.user.uuid });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async toggleShareDetailsRequest({ id, shareDetails }) {
      this.$f7.dialog.preloader();

      await updateFriendRequest({ id, shareDetails, uuid: this.user.uuid });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async toggleShareWishesRequest({ id, shareWishes }) {
      this.$f7.dialog.preloader();

      await updateFriendRequest({ id, shareWishes, uuid: this.user.uuid });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async toggleShareTodosRequest({ id, shareTodos }) {
      this.$f7.dialog.preloader();

      await updateFriendRequest({ id, shareTodos, uuid: this.user.uuid });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async updateFriendTypeRequest({ id, type }) {
      this.$f7.dialog.preloader();

      await updateFriendTypeRequest({ id, type, uuid: this.user.uuid });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async toggleDeceasedRequest({ uuid, deceased }) {
      this.$f7.dialog.preloader();

      await updateDeathRequest({ uuid, deceased });
      await this.getRequestData();

      this.$f7.dialog.close();
    },

    async sendFriendRequest({ friendId }) {
      this.$f7.dialog.preloader();

      await friendRequest({ uuid: this.user.uuid, friendId });
      await this.getRequestData();

      this.$f7.dialog.close();

      // remove entry from array
      this.users = this.users.filter(user => user.id !== friendId);

      this.$f7.dialog.alert('Connect request sent', '');
    },

    onPageBeforeRemove() {
      if (this.popup) this.popup.destroy();
    },
  },
};
</script>
