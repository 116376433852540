export function getMenuData() {
  return [
    {
      id: 'details',
      link: '/details/',
      title: 'Details',
      subtitle: 'Ceremony & Resting Place details.',
      image: 'home.png',
      badge: null,
    },
    {
      id: 'prayers-thoughts',
      link: '/prayers-thoughts/',
      title: 'Prayers & Thoughts',
      subtitle: 'Send prayers and thoughts on various occasions.',
      image: 'family_friends.png',
      badge: null,
    },
    {
      id: 'memories',
      link: '/memories/',
      title: 'Memories',
      subtitle: 'Write your memories.',
      image: 'memories.png',
      badge: null,
    },
    {
      id: 'wishes',
      link: '/wishes/',
      title: 'My Wishes',
      subtitle: 'My wishes after passing away.',
      image: 'wishes.png',
      badge: null,
    },
    {
      id: 'connections',
      link: '/connections/',
      title: 'Family & Friends',
      subtitle: 'My connections.',
      image: 'family_friends.png',
      badge: null,
    },
    {
      id: 'documents',
      link: '/documents/',
      title: 'Planning Checklist',
      subtitle: 'Funeral documents.',
      image: 'documents.png',
      badge: null,
    },
    {
      id: 'about',
      link: '/about/',
      title: 'About',
      subtitle: 'Terms & Condition',
      image: 'about.png',
      badge: null,
    },
  ];
}
