<template>
  <f7-page
    name="wishes-add"
    class="wishesAddPage"
    @page:afterin="afterIn"
  >
    <f7-navbar back-link="Back">
      <f7-nav-title>Wishes</f7-nav-title>
    </f7-navbar>

    <f7-toolbar
      position="bottom"
    >
      <f7-button
        v-if="wish"
        color="red"
        @click="deleteWish"
      >
        Delete Wish
      </f7-button>
    </f7-toolbar>

    <div v-if="wish">
      <f7-card class="inset">
        <f7-card-header class="no-border">
          <img
            :src="`${wish.image || defaultImage}`"
            width="30"
            alt=""
          >

          {{ wish.name || wish.user.name || '' }}
        </f7-card-header>
        <f7-card-content>
          <f7-list>
            <f7-list-item
              v-if="wish.email || wish.user.email"
              :title="wish.email || wish.user.email"
            />
            <f7-list-item
              v-if="wish.phone || wish.user.phone"
              :title="wish.phone || wish.user.phone"
            />
            <f7-list-item
              v-if="wish.user.location"
              :title="wish.user.location"
            />
          </f7-list>
        </f7-card-content>
      </f7-card>

      <f7-list inset>
        <f7-list-input
          type="textarea"
          resizable
          label="Details"
          placeholder="Place details here"
          :value="detailsValue"
          :default-value="detailsValue"
          clear-button
          @input="saveInput($event.target.value, 'details')"
          @change="saveInput($event.target.value, 'details')"
        />
      </f7-list>

      <f7-block>
        <ImageUpload
          :dir="`${user.id}/wishes/${wish.id}`"
          :allow-multiple="true"
          title="Click to add images"
          @upload-completed="uploadCompleted"
        />
      </f7-block>

      <f7-card
        v-for="(image, imageIndex) in wish.images"
        :key="imageIndex"
        class="inset"
      >
        <f7-card-content :padding="false">
          <img
            :src="image"
            width="100%"
            alt=""
          >
        </f7-card-content>
        <f7-card-footer>
          <f7-link />
          <f7-link
            color="red"
            class="small-icon"
            icon-f7="trash"
            @click="deleteWishImage(image)"
          >
            Remove Image
          </f7-link>
        </f7-card-footer>
      </f7-card>
    </div>
  </f7-page>
</template>

<script>
import {
  updateWistRequest,
  wishRequested,
  deleteWistRequest,
  getUser,
  deleteImage,
  getDefaultImage,
} from '../services/backend';
import ImageUpload from '../components/ImageUpload';

export default {
  name: 'WishesAdd',

  components: {
    ImageUpload,
  },

  props: {
    wishId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      defaultImage: null,
      user: null,
      wish: null,
      form: {
        name: null,
        email: null,
        phone: null,
        details: null,
      },
    };
  },

  computed: {
    detailsValue() {
      const { details = null } = this.wish || {};

      return details ? details.replace(/<br>/gm, '\n') : '';
    },
  },

  created() {
    this.user = getUser();
    this.defaultImage = getDefaultImage();
  },

  async mounted() {
    this.$f7.preloader.show();
  },

  methods: {
    async afterIn() {
      await this.getWish();

      this.$f7.preloader.hide();
    },

    async getWish() {
      this.wish = await wishRequested({ id: this.wishId });
    },

    async saveInput(value, name) {
      clearTimeout(this._timerId);

      if (name === 'name') this.form.name = value;
      if (name === 'email') this.form.email = value;
      if (name === 'phone') this.form.phone = value;
      if (name === 'details') {
        this.form.details = value.replace(/(\r\n|\n|\r)/gm, '<br>');
        this.wish.details = this.form.details;
      }

      this._timerId = setTimeout(() => {
        this.updateDetails();
      }, 300);
    },

    async updateDetails() {
      await updateWistRequest({ id: this.wishId, ...this.form });
    },

    async deleteWish() {
      const msg = 'Are you sure you want to remove this wish?';
      await this.$f7.dialog.confirm(msg, '', async() => {
        await deleteWistRequest({ id: this.wishId });

        this.$f7router.navigate('/wishes/', {
          reloadAll: true,
        });
      });
    },

    async uploadCompleted() {
      await this.getWish();
    },

    async deleteWishImage(image) {
      const base = image.split('/last_wish/')[1];
      const s = base.split('/');

      const name = s[s.length - 1];
      const dir = base.replace(`/${name}`, '');

      await deleteImage({ dir, name });

      await this.getWish();
    },
  },
};
</script>
