<template>
  <div>
    <f7-card
      class="inset wish-card"
    >
      <f7-card-header class="no-border">
        <div class="wish-avatar">
          <img
            slot="media"
            :src="`${userData.user.image || defaultImage}`"
            height="30"
            alt=""
          >
        </div>
        <div class="wish-name">
          {{ userData.user.name }}
        </div>
        <div class="wish-date">
          {{ userData.type }} <span v-if="userData.user.days_ago">(Last login {{ daysAgo(userData.user) }})</span>
        </div>

        <f7-icon
          v-if="userData.trusted === YES"
          class="trusted-icon"
          f7="checkmark_alt_circle"
          color="green"
        />
      </f7-card-header>
      <div
        class="wish-mainImage"
        @click="imagePopupOpened = true"
      >
        <img
          v-if="userData.user.image"
          :src="`${userData.user.image}`"
          width="100%"
        >
      </div>
      <f7-card-content>
        <f7-list accordion-list>
          <f7-list-item
            accordion-item
            title="User Details"
          >
            <f7-accordion-content>
              <f7-list>
                <f7-list-item
                  v-if="userData.user.email && userData.user.deceased === NO"
                  title="Email"
                  :after="userData.user.email"
                />
                <f7-list-item
                  v-if="userData.user.email && userData.user.deceased === NO"
                  title="Phone"
                  :after="userData.user.phone"
                />
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
          <f7-list-item
            accordion-item
            :title="`My memories of ${userData.user.name}`"
          >
            <f7-accordion-content>
              <f7-list>
                <f7-list-input
                  type="textarea"
                  placeholder="Add memories here"
                  :value="memories"
                  :default-value="memories"
                  resizable
                  @input="saveInput($event.target.value, 'memories')"
                />
                <f7-list-item
                  v-if="userData.user.deceased === YES"
                  title="This person is deceased"
                />
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </f7-card-content>

      <f7-card-footer class="no-border">
        <f7-link
          v-if="userData.friend.share_details === YES || userData.friend.share_wishes === YES"
          @click="goToConnectionDetails(userData)"
        >
          <f7-icon
            f7="chevron_left"
            class="small-arrow"
          />
          Wishes & Details
        </f7-link>
        <f7-link />
        <f7-link @click="openPopup(userData)">
          Options
          <f7-icon
            f7="chevron_right"
            class="small-arrow"
          />
        </f7-link>
      </f7-card-footer>
    </f7-card>

    <f7-popup
      :opened="imagePopupOpened"
      @popup:closed="imagePopupOpened = false"
    >
      <f7-page style="background: white">
        <f7-navbar title="Profile Image">
          <f7-nav-right>
            <f7-link popup-close>
              Close
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <img
          v-if="userData.user.image"
          :src="`${userData.user.image}`"
          width="100%"
        >
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import { getDefaultImage } from '../../services/backend';

const YES = 'yes';
const NO = 'no';

export default {
  name: 'Friend',

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      YES,
      NO,
      imagePopupOpened: false,
      defaultImage: null,
      form: {
        uuid: null,
        content: this.userData.memory?.content,
      },
    };
  },

  computed: {
    memories() {
      return this.form.content ? this.form.content.replace(/<br>/gm, '\n') : '';
    },
  },

  created() {
    this.defaultImage = getDefaultImage();
  },

  methods: {
    openPopup(request) {
      this.$emit('open-popup', request);
    },

    daysAgo({ days_ago }) {
      return `${days_ago} day${days_ago > 1 ? 's' : ''} ago`;
    },

    goToConnectionDetails({ friend, user }) {
      this.$f7router.navigate('/connection-details/', {
        props: {
          uuid: user.uuid,
          shareDetails: friend.share_details,
          sharWishes: friend.share_wishes,
          shareTodos: friend.share_todos,
        },
      });
    },

    saveInput(value, type) {
      clearTimeout(this._timerId);

      if (type === 'memories') this.form.content = value;

      this._timerId = setTimeout(() => this.updateDetails(), 300);
    },

    async updateDetails() {
      this.form.uuid = this.userData.user.uuid;
      this.form.fromUserId = this.userData.friend.friend_id;

      this.$emit('add-memories', this.form);
    },
  },
};
</script>
