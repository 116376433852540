<template>
  <f7-page
    name="home"
    class="homePage"
    @page:afterin="afterIn"
  >
    <a href="/profile/">
      <f7-block
        :key="imageKey"
        class="text-align-center"
      >
        <div
          :style="`background-image: url('${profileImage}?${imageKey}')`"
          class="profile-image"
        />

        <f7-block-header>
          {{ user ? user.name : '' }}
        </f7-block-header>
      </f7-block>
    </a>

    <f7-list
      class="neumo"
      media-list
      inset
    >
      <f7-list-item
        v-for="menu in menus"
        :key="menu.id"
        class="no-white-space"
        :link="menu.link"
        :title="menu.title"
        :subtitle="menu.subtitle"
        :badge="menu.badge"
      >
        <img
          slot="media"
          class="image-icon"
          :src="`static/images/${menu.image}`"
          width="44"
          :alt="menu.title"
        >
      </f7-list-item>
    </f7-list>

    <f7-sheet
      style="height:auto; --f7-sheet-bg-color: #fff;"
      :opened="sheetOpened"
      :close-on-escape="false"
      :swipe-to-close="false"
      :close-by-backdrop-click="false"
      :close-by-outside-click="false"
      backdrop
    >
      <f7-page-content>
        <Login
          @showSignUp="goToSignUp"
          @signIn="signIn"
        />
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>

<script>
import { getUser, saveUser, saveAbout, login, getImage, getDefaultImage, getRealUser } from '../services/backend';
import { getMenuData } from '../services/about';
import Login from '../components/auth/Login';

export default {
  name: 'Home',

  components: {
    Login,
  },

  data() {
    return {
      imageKey: 0,
      sheetOpened: false,
      aboutPopupOpened: false,
      swiperInit: false,
      user: null,
      image: null,
      menuData: getMenuData(),
    };
  },

  computed: {
    profileImage() {
      const defaultImage = getDefaultImage();

      return this.image || defaultImage;
    },

    menus() {
      return this.menuData;
    },
  },

  async mounted() {
    this.user = getUser();

    if (!this.user) {
      this.sheetOpened = true;
    } else {
      await this.getImg();
    }
  },

  methods: {
    async afterIn() {
      this.user = getUser();
      if(this.user) {
        this.user = await getRealUser({ uuid: this.user.uuid });

        await this.getImg();

        const { pending_requests } = this.user.notifications;

        // update notifications
        this.menuData[4].badge = pending_requests.length;
      }
    },

    async getImg() {
      this.image = await getImage({ uuid: this.user.uuid });
      this.imageKey++;
    },

    closeAboutPopup() {
      saveAbout();
      this.aboutPopupOpened = false;
    },

    async signIn(form) {
      this.$f7.preloader.show();

      const user = await login(form);

      if (user.error) {
        this.$f7.dialog.alert(user.error, '');
      } else {
        await saveUser(user);

        this.sheetOpened = false;
      }

      this.user = getUser();

      this.$f7.preloader.hide();
    },

    goToSignUp() {
      this.sheetOpened = false;

      this.$f7router.navigate('/sign-up/');

      return false;
    },
  },
};
</script>
