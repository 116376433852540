<template>
  <f7-app :params="f7params">
    <!-- Your main view, should have "view-main" class -->

    <f7-view
      main
      class="safe-areas"
      url="/"
    />
  </f7-app>
</template>
<script>
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import cordovaApp from '../js/cordova-app.js';
import routes from '../js/routes.js';

export default {
  data() {
    return {
      f7params: {
        id: 'com.massinflux.oveey',
        name: 'Oveey',
        theme: 'auto',

        // App routes
        routes: routes,

        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      },

    };
  },

  mounted() {
    this.$f7ready(f7 => {
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
    });
  },
};
</script>
